import { ChipProps } from "@mui/material";

import { Requests_Order_By, Vehicles_Order_By } from "shared/generated/graphql";

import { RequestState } from "../../../../../backend/common/states";

export const statusTextMap = new Map<
  RequestState | string | null | undefined,
  string
>([
  [RequestState.AWAITING_SHOP_ACCEPTANCE, "Awaiting Shop Acceptance"],
  [RequestState.SCHEDULED_PICKUP, "Scheduled Dropoff"],
  [RequestState.IN_SHOP, "In Shop"],
  [RequestState.IN_SHOP_AWAITING_APPROVAL, "Awaiting Approval"],
  [RequestState.IN_PROGRESS, "Work In Progress"],
  [RequestState.AWAITING_PAYMENT, "Invoiced"],
  [RequestState.INVOICE_APPROVED, "Invoiced"],
  [RequestState.PAID, "Invoiced"],
  [RequestState.SCHEDULED_DROPOFF, "Scheduled Pickup"],
  [RequestState.DELIVERY_IN_PROGRESS, "Completed"],
  [RequestState.CLOSED, "Completed"],
  [RequestState.ASSIGNED, "Awaiting Estimate"],
  [RequestState.AWAITING_APPROVAL, "Awaiting Approval"],
  [RequestState.APPROVED, "Estimate Approved"],
  [RequestState.INSURANCE_INVOICE_APPROVED, "Insurance Invoice Approved"],
  [undefined, "Unknown"],
  [null, "Unknown"],
]);

export const statusColorMap = new Map<
  RequestState | string | null | undefined,
  ChipProps["color"]
>([
  [RequestState.AWAITING_SHOP_ACCEPTANCE, "warning"],
  [RequestState.ASSIGNED, "info"],
  [undefined, "default"],
  [null, "default"],
]);

export type SortKeyType = keyof Requests_Order_By | keyof Vehicles_Order_By;
export interface RequestHeader {
  label: string;
  xs: number;
  ml?: number;
  requestSortKey: keyof Requests_Order_By;
}
interface VehicleHeader {
  label: string;
  xs: number;
  ml?: number;
  vehicleSortKey: keyof Vehicles_Order_By;
}

export type HeaderType = RequestHeader | VehicleHeader;

export const header: HeaderType[] = [
  {
    label: "Order #",
    xs: 1,
    ml: 2,
    requestSortKey: "id",
  },
  {
    label: "Year",
    xs: 0.5,
    ml: 2,
    vehicleSortKey: "year",
  },
  {
    label: "Make",
    xs: 2,
    ml: 2,
    vehicleSortKey: "make",
  },
  {
    label: "Model",
    xs: 3,
    ml: 1,
    vehicleSortKey: "model",
  },
  {
    label: "License Plate",
    xs: 1.5,
    vehicleSortKey: "plate",
  },
  {
    label: "Mileage",
    xs: 1,
    ml: -0.5,
    vehicleSortKey: "mileage",
  },
  {
    label: "Date & Time",
    xs: 1.5,
    ml: -1,
    requestSortKey: "createdAt",
  },
  {
    label: "Status",
    xs: 1.5,
    ml: -2,
    requestSortKey: "status",
  },
];
