import * as React from "react";

import { isReactNativeWebview } from "shared/lib/reactNativeWebviewUtils";

import Dashboard from "../components/Dashboard";
import RequestsDashboard from "../components/RequestsDashboard/RequestsDashboard";
import { useAuth } from "../providers/AuthProvider";

export default function Home() {
  const { currentUser } = useAuth();

  if (currentUser && isReactNativeWebview(window)) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        messageType: "userGuid",
        userGuid: currentUser.uid,
      })
    );
  }

  return (
    <Dashboard>
      <RequestsDashboard />
    </Dashboard>
  );
}
