import { Dispatch, SetStateAction } from "react";

import { Chip, Typography } from "@mui/material";

import CollapsibleTable from "shared/components/CollapsibleTable/CollapsibleTable";
import UnreadMessageBadge from "shared/components/CollapsibleTable/UnreadMessageBadge";
import {
  CollapsibleTableHeader,
  SortOrderBy,
} from "shared/components/CollapsibleTable/types";
import StyledLink from "shared/components/StyledLink";
import TextWithTooltip from "shared/components/tooltips/TextWithTooltip";
import VehicleImage, {
  VehicleImageHeight,
  VehicleImageWidth,
} from "shared/components/vehicles/VehicleImage";
import { GetAllRequestsShopQuery } from "shared/generated/graphql";
import { getPrettyTime } from "shared/lib/utils";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { statusColorMap, statusTextMap } from "./util";

export type RequestTableType =
  | GetAllRequestsShopQuery["requestsDone"][0]
  | GetAllRequestsShopQuery["requestsInProgress"][0]
  | GetAllRequestsShopQuery["requestsNew"][0];

const RequestsDashboardTable = ({
  requests,
  title,
  loading,
  orderBy,
  setOrderBy,
}: {
  requests: RequestTableType[] | undefined;
  title: string;
  loading?: boolean;
  orderBy?: SortOrderBy;
  setOrderBy?: Dispatch<SetStateAction<SortOrderBy>>;
}) => {
  const { getUnreadMessagesCount } = useUnreadRequests();

  const labels = {
    image: {
      name: "image",
      label: "",
    },
    order: {
      name: "id",
      label: "Order",
    },
    vehicle: {
      name: "vehicle.year",
      label: "YMM",
    },
    licensePlate: {
      name: "vehicle.plate",
      label: "License Plate",
    },
    mileage: {
      name: "vehicle.mileage",
      label: "Mileage",
    },
    createdAt: {
      name: "createdAt",
      label: "Date & Time",
    },
    status: {
      name: "status",
      label: "Status",
    },
  };

  const cellContent = (request: RequestTableType) => {
    return {
      vehicleImage: (
        <VehicleImage
          vehicleId={request.vehicle?.id}
          imageUrl={request.vehicle?.imageUrl}
          widthUsage={VehicleImageWidth.thumbnail}
          heightUsage={VehicleImageHeight.thumbnail}
          sx={{ transform: "scaleX(-1)" }}
        />
      ),
      order: (
        <UnreadMessageBadge
          content={
            <StyledLink href={`requests/${request.id}`}>
              {request.id}
            </StyledLink>
          }
          unreadMessageCount={getUnreadMessagesCount(Number(request.id))}
        />
      ),
      vehicle: (
        <TextWithTooltip
          text={`${request.vehicle?.year} ${request.vehicle?.make} ${request.vehicle?.model}`}
          variant="body2"
        />
      ),
      licensePlate: (
        <TextWithTooltip
          text={request.vehicle?.plate ? request.vehicle?.plate : "--"}
          variant="body2"
        />
      ),
      mileage: (
        <TextWithTooltip
          text={request.vehicle?.mileage ? `${request.vehicle?.mileage}` : "--"}
          variant="body2"
        />
      ),
      createdAt: (
        <Typography variant="body2">
          {getPrettyTime(request.createdAt)}
        </Typography>
      ),
      status: (
        <Chip
          variant="outlined"
          size="small"
          color={statusColorMap.get(request.status) ?? "default"}
          classes={{
            root: statusColorMap.get(request.status) ? "Mui-focusVisible" : "",
          }}
          label={statusTextMap.get(request.status)}
          sx={{ maxWidth: { xs: "160px", sm: "100%" } }}
        />
      ),
    };
  };
  const mobileColumns = (request: RequestTableType) => [
    {
      name: labels.image.name,
      content: cellContent(request).vehicleImage,
    },
    {
      name: labels.order.name,
      content: cellContent(request).order,
    },
    {
      name: labels.status.name,
      content: cellContent(request).status,
    },
  ];
  const mobileSubHeaders = [
    {
      name: labels.vehicle.name,
      label: labels.vehicle.label,
    },
    {
      name: labels.licensePlate.name,
      label: labels.licensePlate.label,
    },
    {
      name: labels.mileage.name,
      label: labels.mileage.label,
    },
    {
      name: labels.createdAt.name,
      label: labels.createdAt.label,
    },
  ];
  const mobileSubColumns = (request: RequestTableType) => [
    {
      name: labels.vehicle.name,
      content: cellContent(request).vehicle,
    },
    {
      name: labels.licensePlate.name,
      content: cellContent(request).licensePlate,
    },
    {
      name: labels.mileage.name,
      content: cellContent(request).mileage,
    },
    {
      name: labels.createdAt.name,
      content: cellContent(request).createdAt,
    },
  ];
  const tabletHeaders = [
    {
      name: labels.image.name,
      label: labels.image.label,
    },
    {
      name: labels.order.name,
      label: labels.order.label,
      sortable: true,
    },
    {
      name: labels.vehicle.name,
      label: labels.vehicle.label,
      sortable: true,
    },
    {
      name: labels.licensePlate.name,
      label: labels.licensePlate.label,
      sortable: true,
    },
    {
      name: labels.status.name,
      label: labels.status.label,
      sortable: true,
    },
  ];
  const tabletColumns = (request: RequestTableType) => [
    {
      name: labels.image.name,
      content: cellContent(request).vehicleImage,
    },
    {
      name: labels.order.name,
      content: cellContent(request).order,
    },
    {
      name: labels.vehicle.name,
      content: cellContent(request).vehicle,
    },
    {
      name: labels.licensePlate.name,
      content: cellContent(request).licensePlate,
    },
    {
      name: labels.status.name,
      content: cellContent(request).status,
    },
  ];
  const tabletSubHeaders: CollapsibleTableHeader[] = [
    {
      name: labels.mileage.name,
      label: labels.mileage.label,
    },
    {
      name: labels.createdAt.name,
      label: labels.createdAt.label,
    },
  ];
  const tabletSubColumns = (request: RequestTableType) => [
    {
      name: labels.mileage.name,
      content: cellContent(request).mileage,
    },
    {
      name: labels.createdAt.name,
      content: cellContent(request).createdAt,
    },
  ];
  const desktopHeaders: CollapsibleTableHeader[] = [
    {
      name: labels.image.name,
      label: labels.image.label,
    },
    {
      name: labels.order.name,
      label: labels.order.label,
      sortable: true,
    },
    {
      name: labels.vehicle.name,
      label: labels.vehicle.label,
      sortable: true,
    },
    {
      name: labels.licensePlate.name,
      label: labels.licensePlate.label,
      sortable: true,
    },
    {
      name: labels.mileage.name,
      label: labels.mileage.label,
      sortable: true,
    },
    {
      name: labels.createdAt.name,
      label: labels.createdAt.label,
      sortable: true,
    },
    {
      name: labels.status.name,
      label: labels.status.label,
      sortable: true,
    },
  ];
  const desktopColumns = (request: RequestTableType) => [
    {
      name: labels.image.name,
      content: cellContent(request).vehicleImage,
    },
    {
      name: labels.order.name,
      content: cellContent(request).order,
    },
    {
      name: labels.vehicle.name,
      content: cellContent(request).vehicle,
    },
    {
      name: labels.licensePlate.name,
      content: cellContent(request).licensePlate,
    },
    {
      name: labels.mileage.name,
      content: cellContent(request).mileage,
    },
    {
      name: labels.createdAt.name,
      content: cellContent(request).createdAt,
    },
    {
      name: labels.status.name,
      content: cellContent(request).status,
    },
  ];

  const dynamicRedirectTo = (id: string | number | null | undefined) =>
    `/requests/${id}`;

  return (
    <CollapsibleTable
      title={title}
      items={requests}
      loading={loading}
      desktopData={{ headers: desktopHeaders, columns: desktopColumns }}
      tabletData={{
        headers: tabletHeaders,
        columns: tabletColumns,
        subHeaders: tabletSubHeaders,
        subColumns: tabletSubColumns,
      }}
      mobileData={{
        columns: mobileColumns,
        subHeaders: mobileSubHeaders,
        subColumns: mobileSubColumns,
      }}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      redirectToTemplate={dynamicRedirectTo}
    />
  );
};

export default RequestsDashboardTable;
